.header {
    background-color: var(--white);
    padding: calc(var(--font-size) * 1.2) 0;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
}