:root {
  --white: #F5F5F5;
  --black: #0A0A0A;
  --font-size: 16px;
  --font-space-grotesk: 'Space Grotesk', sans-serif;
  --header-height: 4rem;
  --footer-height: 3rem;
}

html {
  font-family: var(--font-space-grotesk);
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
  background-color: var(--white);
}

html, body {
  font-weight: 300;
  letter-spacing: -0.01rem;
  line-height: 1.5;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

small {
  text-transform: uppercase;
}

strong {
  font-weight: 700;
  font-size: calc(var(--font-size) * 1.25);
  line-height: 1.35;
}

.alotama {
  font-weight: 700;
  transition: color 0.3s;
}

.alotama:hover {
  color: #b42d2d;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.page-wrapper {
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 45px;
}

.container {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 45px;
}


.horizontal-container {
  display: flex;
  justify-content: space-between;
}